import React from "react"

const CONTACT_US_EMAIL = "sitelcare@medgrocer.com"

const ContactUs = () => {
  return (
    <section id="contact-us">
      <h2>Contact us</h2>
      <hr />
      <div className="message">
        <div className="message-body">
          If you have questions or are experiencing problems not found in our
          Help Center, let us know by emailing us at{" "}
          <a
            href={`mailto:${CONTACT_US_EMAIL}`}
            className="has-text-weight-bold has-text-primary"
          >
            {CONTACT_US_EMAIL}
          </a>
          .
        </div>
      </div>
    </section>
  )
}

export default ContactUs
